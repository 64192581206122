<template>
  <b-overlay :show="pending" opacity="0.5">
    <b-container class="text-left">
      <b-row class="mt-3">
        <b-col>
          <b-form-group
              label="생성할 쿠폰 제목을 입력해 주세요."
              class="mb-0"
              label-size="sm"
          >
            <b-form-input
                v-model="coupon.title"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2 mb-4">
        <b-col>
          <v-date-picker v-model="coupon.period" is-range
                         :model-config="modelConfig"
                         :masks="masks"
                         is-dark
                         color="red">
            <template v-slot="{ inputValue, inputEvents }">
              <b-form-group
                  label="쿠폰 설정 기간"
                  description="쿠폰 설정 기간을 선택하여 주세요"
                  label-size="sm"
              >
                <div class="form-inline form-search">
                  <div class="input-group input-date">
                    <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="" readonly
                           style="background: rgba(0,0,0,.1); width: 150px;"
                           :value="inputValue.start"
                           v-on="inputEvents.start">
                  </div>
                  <div class="date-to"> ~ </div>
                  <div class="input-group input-date mr-4">
                    <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="" readonly
                           style="background: rgba(0,0,0,.1); width: 150px;"
                           :value="inputValue.end"
                           v-on="inputEvents.end">
                  </div>
                </div>
              </b-form-group>
            </template>
          </v-date-picker>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
        <b-form-group
            label="쿠폰 중복 사용 여부"
            v-slot="{ ariaDescribedby }"
            class="col-form-label-sm"
        >
          <b-form-checkbox v-model="coupon.duplication" name="check-button" switch :aria-describedby="ariaDescribedby">
            ({{ coupon.duplication ? '사용' : '미사용' }})
          </b-form-checkbox>
        </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
              label="쿠폰 최대 사용 여부"
              v-slot="{ ariaDescribedby2 }"
              class="col-form-label-sm"
          >
            <b-form-checkbox v-model="coupon.limit.use" name="check-button" switch :aria-describedby="ariaDescribedby2" class="align-middle" @change="setSettingLimit">
              <div class="d-flex justify-content-start">
                <div>({{ coupon.limit.use ? '사용' : '미사용' }})</div>
                <div class="ml-3">
                  <b-input v-if="coupon.limit.use" v-model="coupon.limit.count" type="number"></b-input>
                </div>
              </div>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
              label="포인트 유효기간 여부"
              v-slot="{ ariaDescribedby3 }"
              class="col-form-label-sm"
          >
            <b-form-checkbox v-model="coupon.withdraw" name="check-button" switch :aria-describedby="ariaDescribedby3" @change="setWithdrawDesc">
              <div class="d-flex justify-content-start">
                <div>({{ coupon.withdraw ? '사용' : '미사용' }})</div>
                <div v-if="coupon.withdraw" class="ml-3">
                  기준 내용 입력 : <b-input v-model="coupon.withdrawDesc" style="width: 700px;"></b-input>
                </div>
              </div>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-5">
        <b-col>
          <b>쿠폰 상태 : {{getStatus(coupon.status)}} <span v-if="coupon.status === 'create'" style="color:red">(쿠폰 상태가 "배포 완료" 일 때 사용이 가능합니다.)</span></b>
        </b-col>
      </b-row>

      <b-row>
        <b-button
            v-if="coupon.status !== 'end'"
            variant="primary"
            size="sm"
            class="px-4"
            @click="update">
          수정
        </b-button>
        <div v-if="coupon.status === 'create'">
        <b-button
            variant="info"
            size="sm"
            class="ml-3 px-4"
            @click="reCreate">
          다시 생성
        </b-button>
        <b-button
            variant="success"
            size="sm"
            class="ml-3 px-4"
          @click="setStatusUpdate('deploy')">
          배포
        </b-button>
        </div>
        <b-button
            v-else-if="coupon.status !== 'end'"
            variant="danger"
            size="sm"
            class="ml-3 px-4"
            @click="setStatusUpdate('end')">
          종료
        </b-button>
        <b-button
          variant="warning"
          size="sm"
          class="ml-3"
          @click="createdCouponDownload">
          쿠폰 다운로드
        </b-button>
      </b-row>

      <b-row class="mt-3">
        <div id="info"
             class="border rounded shadow-sm p-4 w-100 d-flex flex-column justify-content-start align-content-start align-items-start">
          <div> <h6><b>총 사용수 : {{getUseCoupons.length}} / {{coupons.length}}</b></h6></div>
          <b-table-simple sticky-header responsive>
            <colgroup>
              <col>
              <col>
            </colgroup>
            <b-thead>
              <b-tr>
                <b-th scope="col" class="text-center">
                  코드
                </b-th>
                <b-th scope="col" class="text-center">
                  포인트
                </b-th>
                <b-th scope="col" class="text-center">
                  사용자
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="getUseCoupons && getUseCoupons.length > 0">
              <b-tr
                  v-for="c of getUseCoupons" :key="`auth-history-${c._id}`"
              >
                <b-td class="text-center">
                  {{ c.code }}
                </b-td>
                <b-td class="text-center">
                  {{c.point}}
                </b-td>
                <b-td class="text-center">
                  {{c.useUser.userId}}
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr class="text-center">
                <b-td colspan="3">쿠폰을 사용한 유저가 없습니다</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import XLSX from "xlsx";
export default {
  name: "deployCouponNew",
  props: {
    coupon: {
      _id: String,
      title: String,
      period: {
        start: String,
        end: String
      },
      duplication: Boolean,
      limit: {
        use: Boolean,
        count: Number
      },
      withdraw: Boolean,
      withdrawDesc: String
    },
  },
  data: () => ({
    pending: false,
    coupons: [],
    modelConfig: {
      type: 'string',
      mask: 'YYYY-MM-DD', // Uses 'iso' if missing
    },
    masks: {
      input: 'YYYY-MM-DD',
    }
  }),

  created() {
    if(this.coupon._id) {
      this.loadCoupon();
    }
  },
  methods: {
    getStatus(status) {
      if (status === 'before') return '생성 전';
      if (status === 'create') return '생성 완료';
      if (status === 'deploy') return '배포 완료';
      if (status === 'end') return '종료';
      if (status === 'expired') return '기간 지남';
      return null;
    },

    setSettingLimit() {
      if (!this.coupon.limit.use) {
        this.coupon.limit.count = 0;
      }
    },

    setWithdrawDesc() {
      if (!this.coupon.withdraw) {
        this.coupon.withdrawDesc = '';
      }
    },

    async update() {
      try {
        const {data} = await this.axios({
          method: 'PUT',
          url: '/admin/coupon-new',
          data: {
            coupon: this.coupon
          }
        });
        const {result} = data;
        if (result) {
          this.$emit('reloadData');
          this.$nextTick(() => {
            this.$bvToast.toast('입력 정보가 수정되었습니다.', {
              title: 'SUCCESS',
              autoHideDelay: 2000,
              variant: 'success',
              appendToast: false,
            });
          })
        }
      } catch (e) {
        console.error(e);
      }
    },

    async loadCoupon () {
      try {
        const {data} = await this.axios({
          method: 'GET',
          url: `/admin/coupon-new/list/${this.coupon._id}`,
          data: {
            coupon: this.coupon
          }
        });
        const {result, coupons} = data;
        if(result) {
          this.coupons = coupons;
        }
      } catch (e) {
        console.error(e);
      }
    },

    async createdCouponDownload() {
      if (this.coupons.length > 0) {
        const workBook = XLSX.utils.book_new();
        const TIME_ZONE = 9 * 60 * 60 * 1000;
        const workSheet = XLSX.utils.json_to_sheet(this.coupons.map(m => {
          let useDate = '';
          if(m.useUser !== null) {
            const targetDate = new Date(new Date(`${m.useUser.useDate}`)+TIME_ZONE);
            const date = targetDate.toISOString().split('T')[0];
            const time = targetDate.toTimeString().split(' ')[0]
            useDate = date + ' '+ time;
          }
          const USE_ADS = m.useAds ? m.useAds.map(m => `${m.title}_${m._id}`).join(', ') : null;
          return {
            CODE: m.code,
            POINT: m.point,
            END_DATE: this.coupon.period.end,
            USE_STATUS: m.useUser ? '사용' : '미사용',
            USE_USERID: m.useUser ? m.useUser.userId : null,
            USE_DATE: useDate,
            WITHDRAW: m.useUser ? m.withdraw  : null,
            REASON: m.reason,
            USE_ADS
          }
        }));
        const timeNow = Date.now();
        workSheet["!cols"] = [{wch: 20}, {wch: 8}, {wch: 12}, {wch: 12}, {wch: 20}, {wch: 17}, {wch: 12}, {wch: 15}, {wch: 20} ];
        let Heading = [['쿠폰 코드', '포인트', '종료 날짜', '사용 여부', '사용 계정', '사용 날짜', '회수 여부', '회수 여부 이유', '쿠폰 사용 광고']];
        XLSX.utils.sheet_add_aoa(workSheet, Heading);
        XLSX.utils.book_append_sheet(workBook, workSheet, "coupon");
        XLSX.writeFile(workBook, `쿠폰 DOWNLOAD_${timeNow}.xlsx`);
      }
    },

    async setStatusUpdate(status) {
      let message = '전체 쿠폰을 배포하시겠습니까? (배포 할 경우 쿠폰 사용이 가능합니다.)'
      if(status === 'end') message = '쿠폰 사용을 종료하시겠습니까?';

      const result = await this.$bvModal.msgBoxConfirm(message, {
        cancelTitle: '취소',
        okTitle: '확인',
        centered: true,
        footerClass: 'justify-content-center',
        bodyClass: 'pt-5 pb-5 text-center',
      });
      if(!result) return;

      this.pending = true;
      try {
        const {data} = await this.axios({
          method: 'PUT',
          url: `/admin/coupon-new/status/${this.coupon._id}`,
          data: {
            status
          }
        });
        const {result} = data;
        if (result) {
          this.coupon.status = status;
          this.$emit('listLoad');
        }
      } catch (e) {
        console.error(e);
      }
      this.pending = false;
    },
    async reCreate() {
      const result = await this.$bvModal.msgBoxConfirm('쿠폰을 지우고 다시 생성하시겠습니까?', {
        cancelTitle: '취소',
        okTitle: '확인',
        centered: true,
        footerClass: 'justify-content-center',
        bodyClass: 'pt-5 pb-5 text-center',
      });
      if(!result) return;
      try {
        const {data} = await this.axios({
          method: 'DELETE',
          url: `/admin/coupon-new/upload/${this.coupon._id}`
        })
        const {result, status} = data;
        if (result) {
          this.coupon.status = status;
          this.$emit('listLoad');
        }
      } catch (e) {
        console.error(e);
      }
    }
  },

  computed: {
    deployCouponCount () {
      if (this.deployCount === 0) return null
      return this.deployCount ;
    },

    getUseCoupons() {
      return this.coupons.filter(f => f.status === 'use');
    }
  }
}
</script>

<style scoped>

</style>
