<template>
  <div id="coupon-detail" class="rounded border p-4 bg-white mt-3">
    <div
        v-if="pending"
        id="pending"
        class="d-flex flex-column justify-content-center align-items-center align-content-center"
    >
      <h1 class="text-danger">
        LOADING
      </h1>
      <b-spinner
          variant="danger"
          size="lg"
      />
    </div>
    <div v-else>
      <div class="d-flex justify-content-between border-bottom pb-2">
        <div class="text-left pt-4">
          <h5><b>제목 : {{couponProject.title}}</b></h5>
        </div>
        <div v-if="couponProject.regDate" class="text-right">
          <div>
            <b-badge>
              {{ new Date(couponProject.regDate).toLocaleString() }}
            </b-badge>
          </div>
          <div>
            <b-badge>
              {{ couponProject.userId }}
            </b-badge>
          </div>
        </div>
      </div>

      <uploadCoupon
          v-if="couponProject.status === 'before'"
          :project-id="couponProject._id"
          @load="reloadData"
      />
      <deploy-coupon-new
          v-else
          :coupon="couponProject"
          @reloadData="reloadData"
      />
    </div>
  </div>
</template>

<script>
import uploadCoupon from "@/components/coupon-new/uploadCoupon.vue";
import DeployCouponNew from "@/components/coupon-new/deployCoupon.vue";
export default {
  name: "couponNewDetail",
  components: {
    DeployCouponNew,
    uploadCoupon
  },
  data: () => ({
    pending: false,
    couponProject: {
      _id: null,
      title: null,
      status: null,
      regDate: null,
      userId: null,
      period: {
        start: null,
        end: null
      },
      duplication: false,
      limit: {
        use: false,
        count: null
      },
      withdraw: false,
      withdrawDesc: '',
      use: true
    },
    modelConfig: {
      type: 'string',
      mask: 'YYYY-MM-DD', // Uses 'iso' if missing
    },
    masks: {
      input: 'YYYY-MM-DD',
    }
  }),
  created () {
    (async () => {
      const {_id} = this.$route.params
      if (_id) {
        this.couponProject._id = _id
        await this.load()
      }
    })()
  },

  methods: {
    async load () {
      this.pending = true
      try {
        const {data} = await this.axios({
          url: `/admin/coupon-new/${this.couponProject._id}`
        })

        const {result, project} = data;
        if (result) this.couponProject = project
      } catch (e) {
        console.error(e)
      }
      this.pending = false
    },

    async reloadData() {
      try {
        await this.load();
        await this.$emit('listLoad');
      } catch (e) {}
    }
  }
}
</script>

<style scoped>
#coupon-detail {
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;
}
#info {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}
</style>
