<template>
  <b-overlay :show="pending" opacity="0.5">
    <b-container>
      <b-row class="py-3">
        <h5><strong>쿠폰 생성</strong></h5>
      </b-row>
      <b-row class="pb-5">
        <b-col cols="12">
          <b-form-group label="포인트" label-for="point" label-cols-lg="2">
            <b-form-input
                id="point"
                type="number"
                v-model="point"
            >
            </b-form-input>
          </b-form-group>

          <b-form-group label="수량" label-for="count" label-cols-lg="2">
            <b-form-input
                id="count"
                type="number"
                v-model="count"

            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="text-left">
          <b-form-group label-cols-lg="2">
            <b-button variant="success" @click="addPoint">추가하기</b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
              :items="items"
              :fields="fields"
              striped
          >
            <template #cell(delete)="row">
              <b-button size="sm" variant="danger" @click="deletePoint(row.index)" class="mr-1">
                삭제
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col >
          <b-button variant="primary" @click="createCoupon">쿠폰 생성</b-button>
        </b-col>
      </b-row>
<!--      <b-row class="pb-4">
        <b-col class="d-inline-flex justify-content-start">
          <div class="align-items-center">
            <b-form-file
                v-model="files"
                placeholder="Choose file"
                drop-placeholder="Drop file here..."
                :disabled="buttonPending"
                @change="excelUpload"
            ></b-form-file>
          </div>
          <div class="ml-3 align-items-center">
            <b-button
                v-if="excelData.length > 0"
                variant="primary"
                siz="sm"
                :disabled="buttonPending"
                v-on:click="save"
            >
              &lt;!&ndash;<font-awesome-icon :icon="['fa', 'file-upload']" />&ndash;&gt;
              업로드
            </b-button>
          </div>
          <div class="mt-2">.xls, .xlsx 파일만 가능합니다.</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="createCouponCount > 0" class="mt-3 text-right">
          <span style="color: red">{{ createCouponCount }}개 생성됨</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <div class="d-flex justify-content-start">
            <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="excelSample"
            >
              엑셀 SAMPLE
            </b-button>
          </div>
        </b-col>
      </b-row>-->
    </b-container>
  </b-overlay>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "uploadCouponNew",
  props: {
    projectId: String
  },
  data: () => ({
    pending: false,
    files: null,
    excelData: [],
    buttonPending: false,
    coupons: [],
    extensions: ["xlsx", "xls"],
    columns: ["POINT"],

    point: null,
    count: null,
    fields: [{label:'포인트', key: 'point'}, {label:'수량', key: 'count'}, {label: '삭제', key: 'delete'}],
    items: []
  }),
  methods: {
    load() {
      this.files = null;
      this.excelData = [];
    },
    modalClose() {
      if (this.coupons.length > 0) this.$emit("load");
      this.$bvModal.hide("uploadCoupon");
      this.reset();
    },

    async excelUpload(event) {
      this.excelData = [];
      const input = event.target;
      if (!input.value) return;
      if (input.files.length === 0) {
        this.$bvToast.toast(
            `업로드 된 파일이 없습니다. 다시 확인하여 주세요.`,
            {
              title: "ERROR",
              autoHideDelay: 5000,
              variant: "danger",
              appendToast: false,
            }
        );
        return;
      }
      const fileName = input.files[0].name;
      const extension = fileName.slice(fileName.indexOf(".") + 1).toLowerCase();
      const extensionFind = this.extensions.filter((f) => f === extension);
      if (extensionFind.length === 0) {
        this.files = null;
        input.value = null;
        this.$bvToast.toast(`.xls, .xlsx 파일만 가능합니다.`, {
          title: "ERROR",
          autoHideDelay: 5000,
          variant: "danger",
          appendToast: false,
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const fileData = reader.result;
        const wb = XLSX.read(fileData, {
          type: "binary",
          cellDates: true,
          dateNF: "YYYY-MM-DD",
        });
        wb.SheetNames.forEach((sheetName) => {
          const rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
          this.excelData = JSON.stringify(rowObj);
        });
      };
      reader.readAsBinaryString(input.files[0]);
    },

    async save() {
      const excelData = JSON.parse(this.excelData);
      const couponCount = excelData.length;

      if (couponCount === 0) {
        return this.$bvToast.toast(
            `생성 할 쿠폰이 없습니다. 다시 업로드 하여 주세요.`,
            {
              title: "ERROR",
              autoHideDelay: 5000,
              variant: "danger",
              appendToast: false,
            }
        );
      }

      if (couponCount > 3000) {
        return this.$bvToast.toast(
            `3000개 이상의 쿠폰을 생성할 수 없습니다. 3000개 이하로 생성하여 주세요.`,
            {
              title: "ERROR",
              autoHideDelay: 5000,
              variant: "danger",
              appendToast: false,
            }
        );
      }

      const columns = Object.keys(excelData[0]);
      if (columns.length !== this.columns.length)
        return this.$bvToast.toast(
            `엑셀 양식이 다릅니다. SAMPLE을 다운받아 정확한 양식으로 업로드하여 주세요.`,
            {
              title: "ERROR",
              autoHideDelay: 5000,
              variant: "danger",
              appendToast: false,
            }
        );

      for (let i in columns) {
        if (columns[i] !== this.columns[i])
          return this.$bvToast.toast(
              `엑셀 양식이 다릅니다. SAMPLE을 다운받아 정확한 양식으로 업로드하여 주세요.`,
              {
                title: "ERROR",
                autoHideDelay: 5000,
                variant: "danger",
                appendToast: false,
              }
          );
      }

      const overPoints = excelData.filter(f => +f.POINT >= 1000).length;
      if (overPoints > 0) {
        const confirm = await this.$bvModal.msgBoxConfirm('업로드한 쿠폰 중 1000 POINT가 넘는 쿠폰이 존재합니다. 그래도 업로드 하시겠습니까?', {
          cancelTitle: '취소',
          okTitle: '확인',
          centered: true,
          footerClass: 'justify-content-center',
          bodyClass: 'pt-5 pb-5 text-center',
        });
        if (!confirm) return;
      }

      const createConfirm = await this.$bvModal.msgBoxConfirm(`${couponCount}개의 쿠폰을 생성하시겠습니까?`, {
        cancelTitle: '취소',
        okTitle: '확인',
        centered: true,
        footerClass: 'justify-content-center',
        bodyClass: 'pt-5 pb-5 text-center',
      });
      if (!createConfirm) return;

      this.panding = true;
      try {
        const { data } = await this.axios({
          url: `/admin/coupon/excelUpload/${this.projectId}`,
          method: "POST",
          data: {
            excelData,
          },
        });
        const { result, coupons } = data;
        if (result) {
          this.$bvToast.toast(`${couponCount}개의 쿠폰이 생성되었습니다.`, {
            title: "SUCCESS",
            autoHideDelay: 5000,
            variant: "success",
            appendToast: false,
          });
          this.$emit('load')
          /*this.buttonPending = true;
          this.coupons = coupons;*/
          /*this.$emit('load')
          this.uploadModalClose()*/
        } else {
          this.$bvToast.toast(`쿠폰 생성을 실패하였습니다.`, {
            title: "DANGER",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: false,
          });
        }
      } catch (e) {
        console.error(e);
      }
      this.panding = false;
    },

    createdCouponDownload() {
      if (this.coupons.length > 0) {
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(this.coupons);
        const timeNow = Date.now();
        workSheet["!cols"] = [{ wch: 18 }, { wch: 10 }, { wch: 10 }];
        XLSX.utils.book_append_sheet(workBook, workSheet, "coupon");
        XLSX.writeFile(workBook, `쿠폰 DOWNLOAD_${timeNow}.xlsx`);
      }
    },

    excelSample() {
      const sample = [
        { POINT: "100" },
        { POINT: "200" },
        { POINT: "300" },
        { POINT: "400" },
      ];
      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(sample);
      workSheet["!cols"] = [{ wch: 15 }];
      const timeNow = Date.now();
      XLSX.utils.book_append_sheet(workBook, workSheet, "create");
      XLSX.writeFile(workBook, `생성 SAMPLE_${timeNow}.xlsx`);
    },

    async createCouponCancel() {
      try {
        if (!confirm("생성 취소하시겠습니까?")) return;
        if (this.coupons.length === 0) {
          this.$bvToast.toast(`생성 취소 할 리스트가 없습니다.`, {
            title: "Error",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: false,
          });
        }

        const { data } = await this.axios({
          url: "/admin/coupon/createCancel",
          method: "DELETE",
          data: {
            coupons: this.coupons,
          },
        });
        const { result, error } = data;
        if (!result)
          return this.$bvToast.toast(error, {
            title: "Error",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: false,
          });

        this.$bvToast.toast(`생성 취소하였습니다.`, {
          title: "SUCCESS",
          autoHideDelay: 5000,
          variant: "success",
          appendToast: false,
        });

        this.reset();
      } catch (e) {
        console.error(e);
      }
    },

    reset() {
      this.files = null;
      this.excelData = [];
      this.buttonPending = false;
      this.coupons = [];
    },

    async addPoint () {
      if(this.point === null || this.point <= 0) {
        return this.$bvToast.toast(`추가할 포인트를 입력해 주세요.`, {
          title: "error",
          autoHideDelay: 3000,
          variant: "danger",
          appendToast: false,
        });
      }

      if(this.count === null || this.count <= 0) {
        return this.$bvToast.toast(`추가할 포인트 수량을 입력해 주세요.`, {
          title: "error",
          autoHideDelay: 5000,
          variant: "danger",
          appendToast: false,
        });
      }


      const confirm = await this.$bvModal.msgBoxConfirm(`${this.point}P - ${this.count}개를 추가하시겠습니까?`, {
        cancelTitle: '취소',
        okTitle: '확인',
        centered: true,
        footerClass: 'justify-content-center',
        bodyClass: 'pt-5 pb-5 text-center',
      });
      if (!confirm) return;


      this.items.push({point: this.point, count: this.count});
      this.point = null;
      this.count = null;
    },

    deletePoint(idx) {
      this.items.splice(idx, 1);
    },

    async createCoupon () {
      if (this.items.length === 0) {
        return this.$bvToast.toast(`등록된 쿠폰이 없습니다. 쿠폰을 추가해 주세요.`, {
          title: "error",
          autoHideDelay: 3000,
          variant: "danger",
          appendToast: false,
        });
      }

      const confirm = await this.$bvModal.msgBoxConfirm(`등록하신 쿠폰을 생성하시겠습니까?`, {
        cancelTitle: '취소',
        okTitle: '확인',
        centered: true,
        footerClass: 'justify-content-center',
        bodyClass: 'pt-5 pb-5 text-center',
      });
      if (!confirm) return;

      try {
        const {data} = await this.axios({
          method: 'POST',
          url: `/admin/coupon-new/coupon-create/${this.projectId}`,
          data: {
            items: this.items
          }
        });
        console.log(data)

        const { result } = data;
        if (result) {
          this.$bvToast.toast(`쿠폰이 생성되었습니다.`, {
            title: "SUCCESS",
            autoHideDelay: 5000,
            variant: "success",
            appendToast: false,
          });
          this.$emit('load');
        }
      } catch (e) {
        console.error(e);
      }
    }
  },

  computed: {
    createCouponCount() {
      return this.coupons.length;
    },
  },
};
</script>

<style scoped></style>
